<template>
{{ getDisplayValue }}
</template>

<script>
import BigNumber from 'bignumber.js'

export default {
  name: 'OutputDecimals',
  props: {
    decimalsValue: {
      type: String
    },
    decimals:{
      type: Number
    }
  },
  computed:{
    getDisplayValue()
    {
      return BigNumber(this.decimalsValue).dividedBy(BigNumber(10).pow(this.decimals)).toString(10);
    }
  }
}

</script>

<style scoped>

</style>