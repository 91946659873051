<template>
  <div class="grid">
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <div
      v-else
      class="col-12 xl:col-9"
    >
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Investment portfolio structure</h5>
          <div>
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-plain p-button-rounded"
              @click="$refs.menu2.toggle($event)"
            ></Button>
            <Menu ref="menu2" :popup="true" :model="items"></Menu>
          </div>
        </div>
        <DataTable
          :value="ownerList"
          dataKey="id"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="rows"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column field="id" header="Id" />
          <Column field="auToken.nameEng" header="Name" />
          <Column field="auToken.mnemonicEng" header="Mnemonic" />
          <Column field="amount" header="Amount">
            <template #body="slot">
              <OutputDecimals
                :decimals-value="slot.data.amount"
                :decimals="slot.data.auToken.decimals"
              />
            </template>
          </Column>
          <Column field="amountBlocked" header="Reserved">
            <template #body="slot">
              <OutputDecimals
                :decimals-value="slot.data.amountBlocked"
                :decimals="slot.data.auToken.decimals"
              />
            </template>
          </Column>
          <Column field="amountFree" header="Free">
            <template #body="slot">
              <OutputDecimals
                :decimals-value="slot.data.amountFree"
                :decimals="slot.data.auToken.decimals"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div
      v-if="!loading"
      class="col-12 xl:col-3"
    >
      <div class="card">
        <h5>Investment portfolio (dynamics of changes)</h5>
        <Chart type="line" :data="lineData" :options="lineOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/AppEventBus'
import ProductService from '../../service/ProductService'
import OutputDecimals from '@/components/edit/OutputDecimals'

export default {
  components: { OutputDecimals },
  data() {
    return {
      loading: false,
      products: null,
      ownerList: [],
      tokenList: [],
      lineData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
        ],
        datasets: [
          {
            label: 'Portfolio value',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: 'Add New', icon: 'pi pi-fw pi-plus' },
        { label: 'Remove', icon: 'pi pi-fw pi-minus' },
      ],
      lineOptions: null,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {
    this.productService
      .getProductsSmall()
      .then((data) => (this.products = data))

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme()
      else this.applyLightTheme()
    }
    EventBus.on('change-theme', this.themeChangeListener)

    if (this.isDarkTheme()) {
      this.applyDarkTheme()
    } else {
      this.applyLightTheme()
    }

    await this.getOwners()
  },
  beforeUnmount() {
    EventBus.off('change-theme', this.themeChangeListener)
  },
  created() {
    this.productService = new ProductService()
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getOwners()
    },
    async getOwners() {
      this.loading = true;
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/au_owners', { headers, params })
        this.ownerList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        console.log('error', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'api/au_owners get error',
          group: 'info',
          life: 3000,
        })
      }
      this.loading = false;
    },

    formatCurrency(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      }
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#ebedef',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#ebedef',
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            },
          },
          y: {
            ticks: {
              color: '#ebedef',
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            },
          },
        },
      }
    },
  },
}
</script>
